@use 'Colors';

.home {
    padding: 16px;
    justify-content: center;
    text-align: left;

    h1 {
        font-size: 3rem;
    }

    .lead-text {
        font-size: 24px;
    }

    .section-title {
        h2 {
            font-size: 2rem;
        }
        padding-bottom: 4px;
        border-bottom: 1px var(--color-header) solid;
    }

    .flex-columns {
        @media (min-width: 480px) {
            display: flex;
        }
    }

    .faq {
        @media (min-width: 480px) {
            width: 50%
        }
    }

    .map {
        @media (min-width: 480px) {
            width: 50%
        }
        max-width: 100%;
        text-align: center;

        a {
            img {
                width: 100%;
                height: auto;
                aspect-ratio: 400/491;
            }
        }
    }
}