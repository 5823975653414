body {
    --color-background: #F4F7F5;
    --color-header: #F0544F;
    --color-text: #000000;
    --color-white: #F4F7F5;
    --color-dark: #6B2737;
    --color-accent: #568ea3;
}
body.dark {
    --color-background: #2E3532;
    --color-header: #F0544F;
    --color-text: #F4F7F5;
    --color-white: #F4F7F5;
    --color-dark: #6B2737;
    --color-accent: #568ea3;
}
